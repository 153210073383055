<template>
  <div>
    <filter-view @submit="loadEchart">
      <purchase-Filter :show-opt="showOpt" :query="query" @init="loadEchart" />
    </filter-view>
    <!-- 分析图表 -->
    <div class="result">
      <div class="padding-10">
        <div v-if="storeChart && storeChart.length">
          <div class="padding-05-0">
            显示图表前
            <el-input-number v-model="showRankNum" size="mini" :min="1" :max="1000" :step="1" :precision="0" controls-position="right" @change="draw" />&nbsp;名
          </div>
          <div class="padding-05-0" ref="gdPieChart"></div>
          <div class="padding-05-0" ref="gdBarChart"></div>
        </div>
        <div class="fc-g ta-c" v-if="!storeChart || !storeChart.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
      </div>
      <div class="padding-10" v-if="storeChart && storeChart.length > 0">
        <div>数据列表</div>
        <div v-for="(o, i) in storeChart" :key="i" class="text item padding-10-0">
          <div class="c h">
            <el-card class="box-card" shadow="never" style="width: 50%">
              <div>销售金额</div>
              <div style="color: red">{{ $price(o.realSumMoney) }}</div>
              <div>优惠前金额</div>
              <div style="color: red">{{ $price(o.sumMoney) }}</div>
              <div>数量</div>
              <div style="color: red">{{ o.sendNumber }}</div>
            </el-card>
            <el-card class="box-card" shadow="never" style="width: 50%">
              <div>发货金额</div>
              <div style="color: red">{{ $price(o.realSendMoney) }}</div>
              <div>优惠前金额</div>
              <div style="color: red">{{ $price(o.sendMoney) }}</div>
              <div>数量</div>
              <div style="color: red">{{ o.sendNumber }}</div>
            </el-card>
          </div>
        </div>
        <div class="ta-c">
          <el-pagination :total="totalElements" :current-page="page" :page-size="size" small layout="prev, pager, next" @current-change="handleCurrentChange" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import filterView from "../assembly/filter";
import purchaseFilter from "../assembly/filter/purchase";
import request from "@/utils/request";
export default {
  components: {
    filterView,
    purchaseFilter,
  },
  data() {
    let now = new Date();
    return {
      page: 1,
      size: 6,
      totalElements: null,
      storeChart: [],
      showRankNum: 3,
      chart: {
        gdBarChart: null,
        gdPieChart: null,
      },
      showOpt: {
        showSeller: true, //显示供应商sellerId
        showRangGoods: true, //商品范围
        showCommodity: true, //指定商品
        showLevel: true, //汇总级别
        showRankGist: true, //排名依据
        showCompareyear: false, //同比年度
        showShop: false, //显示直营门店
        showDimension: false, //分析纬度
        showYearonyear: false, //同比
        showMonth: false, //环比
        showRank: false, //排名对象
        showCategory: false, //对比类型
        showReported: false, //显示只统计已上报
      },
      query: {
        addressType: "area",
        StandardAreaGroup: null,
        sellerId: null,
        dateGroup: "day",
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        brandId: null,
        seriesId: null,
        categoryId: null,
        goodsGroup: "brandId", //汇总级别
        saleValueGroup: "realSumMoney", //排名依据
        goods: null, //指定商品
        page: 0,
        size: 10000,
      },
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.loadEchart();
    },
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      if (data.addressType == "standard") {
        if (this.query.StandardAreaGroup == 0) {
          data.StandardAreaGroup = "country";
        }
        if (this.query.StandardAreaGroup == 1) {
          data.StandardAreaGroup = "province";
        }
        if (this.query.StandardAreaGroup == 2) {
          data.StandardAreaGroup = "city";
        }
        if (this.query.StandardAreaGroup == 3) {
          data.StandardAreaGroup = "district";
        }
        delete data.treeNodeType;
      } else {
        data.treeNodeType = this.query.treeNodeType;
        delete data.StandardAreaGroup;
      }
      if (data.goods) data.skuId = data.goods.id;
      delete data.goods;
      return data;
    },
    loadEchart() {
      let data = Object.assign({}, this.makeParams(), {
        page: this.page - 1,
        size: this.size,
      });
      request({
        url: "@host:analysis;api/analy/purchase/goods",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.storeChart = res.content;
          this.totalElements = res.totalElements;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .catch((err) => {
          this.storeChart = [];
        });
    },
    draw() {
      this.drawGdBarChart();
      this.drawGdPieChart();
    },
    drawGdBarChart() {
      if (!this.chart.gdBarChart) {
        this.chart.gdBarChart = echarts.init(this.$refs.gdBarChart, "light", {
          height: 320,
        });
      }
      let xName = [];
      let xData = [];
      let yName = [];
      let svgp = [];
      let seriesData = [];
      if (
        this.query.saleValueGroup == "saleNumber" ||
        this.query.saleValueGroup == "checkSaleNumber" ||
        this.query.saleValueGroup == "sendNumber" ||
        this.query.saleValueGroup == "checkSendNumber"
      ) {
        yName.push("数量");
        (this.storeChart || []).forEach((o) => {
          xData.push(o.name);
          seriesData.push(o[this.query.saleValueGroup]);
        });
      } else {
        yName.push("金额(万元)");
        (this.storeChart || []).forEach((o) => {
          xData.push(o.name);
          seriesData.push(
            Math.round(o[this.query.saleValueGroup] * 0.000001 * 100) / 100
          );
        });
      }
      switch (this.query.goodsGroup) {
        case "brandId":
          xName.push("品牌结构");
          break;
        case "seriesId":
          xName.push("系列结构");
          break;
        case "goodsCategoryId":
          xName.push("分类结构");
          break;
        case "spuId":
          xName.push("商品结构");
          break;
      }
      switch (this.query.saleValueGroup) {
        case "realSumMoney":
          svgp.push("销售金额");
          break;
        case "realCheckSumMoney":
          svgp.push("已上报销售金额");
          break;
        case "saleNumber":
          svgp.push("销售数量");
          break;
        case "checkSaleNumber":
          svgp.push("已上报销售数量");
          break;

        case "sendMoney":
          svgp.push("发货金额");
          break;
        case "checkSendMoney":
          svgp.push("已上报发货金额");
          break;
        case "sendNumber":
          svgp.push("发货数量");
          break;
        case "checkSendNumber":
          svgp.push("已上报发货数量");
          break;
      }
      this.chart.gdBarChart.clear();
      this.chart.gdBarChart.setOption({
        title: {
          text: xName + svgp + "前" + this.showRankNum,
          left: "center",
        },
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          y2: 150,
          left: "6%",
          right: "6%",
          bottom: "4%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData.slice(0, this.showRankNum),
            axisLabel: {
              interval: 0,
              formatter: function (value) {
                return value.split("").join("\n");
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: yName,
          },
        ],
        series: [
          {
            name: xName,
            type: "bar",
            data: seriesData.slice(0, this.showRankNum),
          },
        ],
      });
    },
    drawGdPieChart() {
      if (!this.chart.gdPieChart) {
        this.chart.gdPieChart = echarts.init(this.$refs.gdPieChart, "light", {
          height: 320,
        });
      }
      let xName = [];
      let svgp = [];
      let arry = this.storeChart;
      let sgp = this.query.saleValueGroup;
      let newArray = this.storeChart.map((o) => {
        if (
          sgp == "saleNumber" ||
          sgp == "checkSaleNumber" ||
          sgp == "sendNumber" ||
          sgp == "checkSendNumber"
        ) {
          return {
            name: o.name,
            value: o[sgp] == 0 ? null : o[sgp],
          };
        } else {
          return {
            name: o.name,
            value: o[sgp] == 0 ? null : Math.round(o[sgp] * 0.01 * 100) / 100,
          };
        }
      });
      switch (this.query.goodsGroup) {
        case "brandId":
          xName.push("品牌");
          break;
        case "seriesId":
          xName.push("系列");
          break;
        case "goodsCategoryId":
          xName.push("分类");
          break;
        case "spuId":
          xName.push("商品");
          break;
      }
      switch (this.query.saleValueGroup) {
        case "realSumMoney":
          svgp.push("销售金额");
          break;
        case "realCheckSumMoney":
          svgp.push("已上报销售金额");
          break;
        case "saleNumber":
          svgp.push("销售数量");
          break;
        case "checkSaleNumber":
          svgp.push("已上报销售数量");
          break;
        case "sendMoney":
          svgp.push("发货金额");
          break;
        case "checkSendMoney":
          svgp.push("已上报发货金额");
          break;
        case "sendNumber":
          svgp.push("发货数量");
          break;
        case "checkSendNumber":
          svgp.push("已上报发货数量");
          break;
      }
      this.chart.gdPieChart.clear();
      this.chart.gdPieChart.setOption({
        title: {
          text: xName + svgp + "汇总分析前" + this.showRankNum,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        series: [
          {
            name: xName + svgp,
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: newArray.slice(0, this.showRankNum),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
  },
};
</script>



